.empty{
  @extend .text;
  hr{
    visibility: hidden;
    margin: 0;
  }
}
.pad-right{
  @include media(min-width 768px) {
    margin-right: calc(100% / 6);
  }
}

.padding-bottom-lg{
  padding-bottom: 12%;
}
section .circle-span{
  padding-left: 65px;
  position: relative;
  span{
    font-size: 1.1em;
    font-weight: 400;
    position: absolute;
    padding-top: 4px;
    left: 0;
    color: #fff;
    background-color: $brand-red;
    border-radius: 50%;
    width: 53px;
    height: 53px;
    text-align: center;
    letter-spacing: 0;
    &:before, &:after{
      content: '';
      position: absolute;
      display: block;
      margin: auto;
      @include media(max-width 767px) {
      display: none;

      }
    }
    &.point-left:before{
      content: url('../img/front/svgs/point-left.svg');
      height: 18px;
      width: 12px;
      left: -14px;
      bottom: 0;
      top: 0;
    }
    &.point-right:after{
      content: url('../img/front/svgs/point-right.svg');
      height: 18px;
      width: 12px;
      right: -14px;
      bottom: 0;
      top: 0;
    }
    &.point-down:after{
      content: url('../img/front/svgs/point-down.svg');
      height: 12px;
      width: 18px;
      right: 0;
      left: 0;
      bottom: -14px;
    }
  }
}

.large-tablet-image{
  overflow-x: hidden;
  img{
    z-index: 2;
    @include media(min-width 768px) {
    position: absolute;
    }
  }
  &.one-left{

    img{
      top: 0;
      left: -70px;
      @include media(max-width 1000px ) {
        width: 55%;
        left: -5%;
      }
      @include media(max-width 768px ) {
        min-width: 290px;
        left: 0;
      }
    }
  }
  &.two-right{
        img{
      max-width: 55%;
      left: 52%;
      @include media(max-width 1100px ) {
      left: 49%;
      }
      @include media(max-width 768px ) {
      width: 90%;
      max-width: 400px;
      right: 0;
      }
    }
  }
  &.three-left{
    img{
      top: -140%;
      left:-8%;
      max-width: 59%;
      @include media(max-width 1350px ) {
        top: -80%;
      }
      @include media(max-width 767px ) {
        left: 0%;
        max-width: 100%;
      }
      @include media(max-width 480px ) {
        display: none;
      }
    }
  }
  &.four-left{
    img{
      top: -100%;
      left: -8%;
      max-width: 55%;
      @include media(max-width 1350px ) {
        top: -50%;
        left: -7%;
        width: 550px;
      }
    }
  }
  @include media(max-width 767px) {
  text-align: center;
  }
}

#step-8{
  @include media(max-width 950px) {
  padding-top: 50px;
  }
  @include media(max-width 767px) {
  padding-top: 0;
  padding-bottom: 100px;
  }
}
