.nav-container{
  z-index: 1;
  height: 160px;
  background-color: white;
  @include media(max-width 1220px) {
    height: 120px;
  }
  @include media(max-width 768px) {
    height: auto;
    padding-bottom: 0;
    .container{
      width: 100%;
      padding: 0 4%;
    }
  }
  .brand{
    padding: 24px 0 20px;
        .logo img{
        height: 68px;
        width: auto;

        }
    @include media(max-width 1220px) {
      padding: 5px 0 5px;
      display: inline-block;
    }
    .logo img{
      @include media(max-width 580px) {
        height: 50px;
      }
    }
  }
  .nav-red-button{
    position: absolute;
    right: 0;
    top: 0;
    @extend .button-red;
  }
  .nav-red-button.offer{
    @include media(max-width 768px) {
    display: none;
    }
  }
  .nav-red-button.nav-toggle{
    display: none;
    top: 5px;
    right: 4%;
    width: 55px;
    height: 50px;
    padding: 0;
    cursor: pointer;
    // border-bottom-right-radius: 0;
    // border-bottom-left-radius: 20px;
    .toggle-bar{
      width: 64%;
      height: 2px;
      margin: 0 18%;
      margin-top: calc(24px);
      background-color: white;
      &:before{
        content: '';
        width: 64%;
        height: 2px;
        margin: 0;
        background-color: white;
        position: absolute;
        top: 12px;
      }
      &:after{
        content: '';
        width: 64%;
        height: 2px;
        margin: 0;
        background-color: white;
        position: absolute;
        bottom: 12px;
      }
    }
  }
  .nav-red-button.nav-toggle{
    @include media(max-width 768px) {
    display: inherit;
    }
  }
}
.strapline, .main-nav{
  display: inline-block;
}
.strapline{
  text-transform: uppercase;
  font-weight: 300;
  font-style:italic;
  @include red-text();

  span{
    br{
      display: none;
      @include media(max-width 1220px) {
        display: inherit;
      }
    }
  }
  @include media(max-width 1220px) {
  padding-top: 5px;
  vertical-align: top;
  line-height: 1.4em;
  }
  @include media(max-width 580px) {
  font-size: 0.9em;
  padding-top: 0;
  vertical-align:super;
  }
  @include media(max-width 470px) {
  font-size: 0.75em;
  }
  @include media(max-width 425px) {
  display: none;
  }
}

.main-nav{
  float: right;
  ul{
    li{
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.73em;
      display: inline-block;
      letter-spacing: 2px;
      a{
        padding: 2px 10px;
      }

    }
    li.active a{
      @include red-text();
    }
    li:last-of-type{
      a{
        padding-right: 0;
      }
    }
  }
  @include media(max-width 1220px) {
    display: block;
    float: none;
    padding-top: 6px;
    ul{
      float: right;
    }
  }
  @include media(max-width 768px) {
    position: absolute;
    z-index: 11;
    width: 100%;
    padding-top: 0;
    top: auto;
    transition: all 0.5s ease;
    overflow-x: hidden;
    ul{
      text-align: center;
      margin: auto;
      background-color: #fff;
      width: 100%;
      padding: 1em 0 0;
      box-shadow: 0 3px 1px rgba(#ddd, 0.4);
      li{
        padding: 1.5em 0;
        display: block;
        box-shadow: 0 3px 5px rgba(#ddd, 0.4);
        &:first-of-type{
          border-top: none;
        }
        a{
          padding: 1em 0;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .nav-container{
    overflow: hidden;
  }
  .nav-container.nav-show{
    transition: all 0.5s ease;
    overflow: visible;
    .main-nav {
      right: 0;
      ul {
        li {

        }
      }
    }
  }
  .nav-container.hidden{
    .main-nav {
      transition: all 0.5s ease;
      right: -100%;
    }
  }
}

.hide-onload{
  transition: all 0.5s ease-in;
  opacity: 0;
}

.hide-onload.fixed{
  z-index: 8;
  -webkit-box-shadow: 0px 3px 5px 1px rgba(#444,0.2);
  -moz-box-shadow: 0px 3px 5px 1px rgba(#444,0.2);
  box-shadow: 0px 3px 5px 1px rgba(#444,0.2);
  height: 74px;
  position: fixed;
  top: 0;
  display: block;
  width: 100%;
  @include media(max-width 900px) {
    height: 60px;
  }
  .main-nav{
    padding-top: 24px;
    @include media(max-width 900px) {
      top: 60px;
      padding-top: 18px;
    }
    @include media(max-width 768px) {
      padding-top: 0;
    }
  }
  .brand{
    position: absolute;
    padding: 3px 0 0 0;
    top: 0;
    @include media(max-width 900px) {
      padding: 5px 0 0 0;
      img{
        height: 50px;
      @include media(max-width 860px min-width 768px) {
        opacity: 0;
        }
      }
    }
  }

}
.display-none{
  display: none;
}

.slow-reveal{
  opacity: 1!important;
  transition: all 0.5s ease-in;
}
