.container.form{
  padding: 0 10%;
  text-align: left;
  @include media(max-width 550px) {
    padding: 0%;
  }
  form.form{
    .form-element{
      width: 100%;
      display: block;
      margin-bottom: 1.5em;
    }
    input[type="text"], input[type="email"], input[type="password"]{
      padding: 8px 5px;
    }
    input[type="submit"]{
      cursor: pointer;
    }
    label{
      float: left;
    }
    #submit{
      max-width: 400px;
      min-width: 310px;
      text-align: center;
      margin: 1.5em 0;
    }
  }
}


.login {
  background-color: $grey-bg-light;
  padding-bottom: 0;
    text-align: center;
  .container.form{

    padding: 100px 100px;
    @include media(max-width 768px) {
    padding: 60px 5%;
    width: 100%;
    }
    text-align: left;
    form.form{
      text-align: center;
      max-width: 768px;
      padding: 50px;
      margin: auto;
      background-color: white;
      -webkit-box-shadow: 9px 6px 16px -5px rgba(184,184,184,1);
      -moz-box-shadow: 9px 6px 16px -5px rgba(184,184,184,1);
      box-shadow: 9px 6px 16px -5px rgba(184,184,184,1);
      @include media(max-width 768px) {
      padding: 50px 20px;
      }
      .button.std-button{
        max-width: 400px;
        margin: 0.5em auto 1em;
        width: 100%;
        border: none;
      }
    }
  }
}

.redh2{
  color: $brand-red;
}
