.features-comparison-table {
	table-layout: fixed;
	width: 100%;
	margin-bottom: 6em;

	thead tr th {
		padding: 0.75em;
	}

	th , td {
		padding: 0.7em;
		border: 1px solid #ddd;
		border-top: none;
	}

	@media (max-width: 768px) {
		tbody tr {
			&:first-child {
				td {
					&:first-child {
						border-top: 1px solid #ddd;
					}
				}
			}
		}

		tbody tr td {
			&:first-child {
				background-color: $grey-bg-light;
				font-size: 87.5%;
			}
		}
	}

	@media (min-width: 768px) {

		td, th {
			border-right: none;

			&:first-child {
				border-left: none;
			}
		}

		tbody tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}

	td {
		display: block;
		text-align: center;
	}

	th {
		display: none;
	}


	@media (min-width: 768px) {

		td {
			text-align: left;
		}

		th, td {
			display: table-cell;
		}
	}


	.response {

		text-align: center;

		span {
			color: $font-grey;
			font-size: 0.85em;
		}

		@media (min-width: 768px) {
			font-size: 1.4em;
			span {
				display: none;
			}
		}

		&.yes {
			color: $brand-red;
		}

		&.no {
			color: lighten($font-grey, 33%);
		}
	}




}
