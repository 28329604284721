.row-background.padding-top{
    padding-top: 100px;
    @include media(max-width 768px) {
      padding-top: 50px;
    }
}

.row-background{
  background-color: $white;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  padding-bottom: 100px;
  @include media(max-width 768px) {
    padding-bottom: 50px;
  }
  .box{
    background-color: rgba($white, 0.8);
    color: $font_grey;
    width: 50%;
    border-bottom-right-radius: 65px;
    padding-top: 90px;
    padding-bottom: 90px;
    min-height: 400px;
    @include media(max-width 768px) {
      min-height: auto;
      width: 90%;
      padding-top: em(30px);
      padding-bottom: em(30px);
      border-bottom-right-radius: 54px;
    }
  }
  .left-box{
    .box-content{
      margin-left: auto;
      .text{
      padding-right: calc((100% / 6));
        @include media(max-width 1060px) {
          padding-right: calc((100% / 10));
        }
      }
    }
  }
  .right-box{
    margin-right: 0;
    margin-left: 50%;
    @include media(max-width 1060px) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 54px;
    }
    .box-content{
      padding: 0;
      .text{
        padding-right: calc((100% / 6));
        padding-left: calc((100% / 6));
        @include media(max-width 1060px) {
          padding-right: calc((100% / 10));
          padding-left: calc((100% / 10));
        }
      }
    }
  }
  .box.red{
    background-color: rgba($brand-red, 0.8);
    color: $white;

  }
  .box.black{
    background-color: $black-bg;
    color: $white;
  }
  .white-with-red{
    .text{
      h2{
    @include red-text();
      }
      ul li:before{
    @include red-text();
      }
    }
  }
  .feature-image{
      display: block;
    @include media(min-width 768px) {
      position: absolute;
      height: 100%;
      width: 50%;
    }
    .image-container.video{
      width: 100%;
      position: relative;
      text-align: center;
        @include media(max-width 768px) {
          padding: 50px 0;
        }
      img{
        @include media(max-width 1220px) {
          width: 90%;
          max-width: 400px;
        }
      }
    }
  }
}
.row-centre{
    text-align: center;
    padding: 100px 0;
}
.row-centre.red{
  background-color: $brand-red;
  color: $white;

  .container{
    line-height: 1.8em;
    text-align: center;

    h2{
      font-weight: 100;
      font-size: 2em;
    }
    p{
      font-size: 1.25em;
      font-weight: 100;
    }
    p:first-of-type{
      max-width: 750px;
      margin: auto auto 2em;
    }
    .button{
      width: 80%;
      max-width: 610px;
      margin: auto;
    }
  }
}
.feature-image.right{
  right: 0;
  top: 0;
  padding-top: 100px;
  @include media(max-width 768px) {
    padding: 50px 0;
  }
}
section {
  .hideon-large{
    @include media(min-width 767px) {
      display: none;
    }
  }
  .button.hideon-small{
    @include media(max-width 767px) {
      width: auto;
      span{
        display:none;
      }
    }
  }
  .button.shrinkon-small{
    @include media(max-width 400px) {
      padding-left: 20px!important;
      padding-right: 20px!important;
      width: 100%!important;
    }
  }
}
.row-centre.red .container .button{
  @include media(max-width 767px) {
    width: auto;
    display: inline-block;
    padding: 1.4em 2.4em;
    text-transform: capitalize;
  }
}

.row-two{
  @extend .row-background;
  .container{
    @include outer-container;
  }
  .box-content{
    @include span-columns(5 of 12);
    @include media(max-width 1060px) {
      @include span-columns(10 of 22);
    }
    @include media(max-width 768px) {
      @include span-columns(12 of 12);
    }
  }
  .box-content:nth-child(2n-1){
    @include media(max-width 768px) {
      margin-bottom: 50px;
    }

  }
  .box-content:nth-child(2n){
    @include media(min-width 768px) {
      @include shift(2 of 22);
    }
    @include media(min-width 1060px) {
      @include shift(2 of 12);
    }
  }
}

section.grey-bg{
  background-color: $grey-bg-light;
}


.spacer-lg {
padding-top: 12.5%;
}
