h1, h2, h3, h4, h5, h6, p, a, span, strong, em, ul, li, body, html{
  padding: 0;
  margin: 0;
  font-family: $roboto-font-family;
}


h1, h2, h3, h4, h5, h6, p, a {

}

h5{
  font-size: 1.1em;
  font-weight: 500;
}
p{
  font-weight: 300;
}
a{
  text-decoration: none;
  color: inherit;
}

body{
  font-size: 1.2em;
  color: $font-grey;
}
h3{
  font-size: 1.2em;
}


body, html{
  overflow-x: hidden;
}

section h2, section h1{
  font-weight: 100;
}
