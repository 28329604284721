.button-radius{
  padding: 24px 30px;
  border-bottom-right-radius: 16px;
  font-weight: 500;
  line-height: 1em;
  p{
    letter-spacing: 2px;
    font-size: 1.3em;
  }
}

.button-red{
@extend .button-radius;
background-color:  $brand-red;
color: $white;
  p{
    color: $white;
  }
}

.button{
  padding: 50px 40px;
  border-bottom-right-radius: 60px;
  font-weight: 400;
  line-height: 1em;
}
.button.black-trans{
  background-color:  rgba(black, 0.15);
  color: $white;
  border: 1px solid rgba(white, 0.8);
  font-size: 1.35em;

}

.std-button{
  padding: 26px 35px;
  border-bottom-right-radius: 30px;
  font-weight: 500;
  line-height: 1em;
  font-size: 1.5em;
  background-color:  $brand-red;
  color: $white;
  text-align: center;
  &.two-thirds{
    max-width: 66%;
    min-width: 225px;
  }
}
