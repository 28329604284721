.mega-feature{
  @extend .row-background;
  padding-bottom: 0;
  .container{
    @include outer-container;
    .table-row{
      @include media(min-width 768px) {
        @include row(table);
      }
    }
  }
  .box-content{
    @include span-columns(6 of 12);
    @include media(max-width 768px) {
      @include span-columns(12 of 12);
      display: block;
      position: relative;
    }
  }

  &.left{
    .box-content:nth-child(1){
      @include media(max-width 768px) {
      padding-right: 0;
      }
    }
    .box-content:nth-child(2){
      padding-bottom: 100px;
      padding-right: calc(100% / 12);
      @include media(max-width 768px) {
      padding-right: 0;
      padding-bottom: 50px;
      }
    }
    .tablet{
      @include media(min-width 1090px) {
      left: -5%;
      }
    }
  }
  &.right{
    .box-content:nth-child(1){
      padding-bottom: 100px;
      padding-right: calc(100% / 12);
      @include media(max-width 768px) {
      padding-right: 0;
      padding-bottom: 50px;
      position: relative;
      }
    }
    .box-content:nth-child(2){
      @include media(max-width 768px) {

      padding-bottom: 50px;
      }
    }
    .tablet{
      right: 0;
      @include media(min-width 1090px) {
      right: -5%;
      }
    }
  }
  .image{
    @include media(max-width 768px) {
      text-align: center;
    }
  }
  .tablet{
    position: absolute;
    height: 100%;
    width: auto;
    bottom: 0;
    background-repeat: no-repeat;
    @include media(max-width 1150px min-width 767px) {
      width: 48%;
      height: auto;
      top:0;
      bottom: 0;
      margin: auto;
    }
    @include media(max-width 768px) {
    position: relative;
    width: 90%;
    max-width: 470px;
    height: auto;
    margin: auto;
    }
  }
  .text{

    @include media(max-width 768px) {
      padding-left: 10%;
      &.no-pad{
        padding-left: 0;
      }
    }
  }
}

#tablet-png{
  @include media(max-width 768px) {
    padding-bottom: 0;
  }
}
