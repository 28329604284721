.block-quote{
  @extend h2;
  font-style: italic;
  font-weight: 100;
  font-size: 1.7em;
  &:before{
    content: open-quote;
  }
  &:after{
    content: close-quote;
  }
  @include media (max-width 768px){
      font-size: 1.3em;
  }
}
.quote-footer{
  color: $brand-red;
  letter-spacing: 1px;
  font-size: 1.2em;
}
.footer-upper.row-background{
  background-color: #000;
  .left-box{
    color: $white;
    border-radius: 0;
    background-color: transparent;;
    padding: 0;
    min-height: auto;
    .column{
      display: inline-block;
      width: 40%;
      margin-right: 10%;
      vertical-align: top;
      @include media(max-width 768px) {
        width: 45%;
        margin-right: 0;
        &:first-of-type{
          margin-right: 10%;
        }
      }
      @include media(max-width 400px) {
        width: 48%;
        &:first-of-type{
          margin-right: 2%;
        }
      }
      h4{
        font-weight: 100;
        font-size: 1.5em;
        @include media(max-width 400px) {
          font-size: 1.3em;
        }
      }
      h4:last-of-type{
        margin-top: 2em;
        @include media(max-width 1120px) {
          margin-top: 0.8em;
        }
      }
      li{
        font-weight: 300;
        list-style: none;
        font-size: 0.85em;
        a{
          padding: 1em 0;
          line-height: 2.5em;
        }
      }
    }
  }
  .feature-image.right{
    padding: 0;

    .image-container{
      width: 100%;
      position: relative;
        @include media(max-width 768px) {
          padding: 50px 0 0 0;
          text-align: center;
        }
      img{
        margin-top: -44px;
        @include media(max-width 1220px) {
          width: 100%;
        }
        @include media(max-width 768px) {
          width: 80%;
          max-width: 470px;
          margin-bottom: -6%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .button.black-trans{
        background-color:  rgba(black, 0.4);
        width: 560px;
        max-width: 90%;
        display: inline-block;
        font-weight: 100;
        padding: 30px 85px;
        top: 65%;
        position: absolute;
        left: 5%;
        text-align: center;
        @include media(max-width 768px) {
          left: 0;
          right: 0;
          margin: auto;
          display: inline-block;
          width: 70%;
          min-width: 280px;
          padding-left: 10px;
          padding-right: 10px;
        }
        @include media(max-width 400px) {
          top: auto;
          bottom: 20px;
        }
      }
    }
  }
  @include media(max-width 1120px) {
    padding: 5% 0;
  }
  @include media(max-width 768px) {
    padding-bottom: 0;
  }
}
.footer-lower{
  background-color: $grey-footer;
  color: white;
  padding: 45px 0;
  font-weight: 300;
  font-size: 0.75em;
  ul.credits{
    text-align: center;
    li{
      display: inline-block;
      @include media(max-width 540px) {
        display:block;
        a{
          line-height: 2em;
        }
        &:after{
          display: none;
        }
      }
      a{
        padding: 1em;
      }
      &:after{
        content: '\2022';
        margin: 0 0 0 5px;
      }
    }
    li:last-of-type{
      &:after{
        display: none;
      }
    }
  }
}
