.container{
  position: relative;
  margin: auto;
  width: 1170px;
  @include media(max-width 1220px) {
    width: 980px;
  }
  @include media(max-width 1060px) {
    width: 90%;
  }
  &.narrow{
    max-width: 750px;
  }
}
section.central-row{
  @extend .container;
  padding: 50px 0;
  border-bottom: 1px solid rgba(100,100,100, 0.3);
  border-top: 1px solid rgba(100,100,100, 0.3);
  h2{
    margin-top: 1em;
    font-size: 1.75em;
  }
}

.box{
  .box-content{
    width: calc(1170px/2);
  }
}

.box.left-box{
  .box-content{
    @include media(max-width 1220px) {
      width: calc(980px/2);
    }
    @include media(max-width 1060px) {
      width: 90%;
    }
  }
}

.row-background .box.right-box{
  width: calc(1170px/2);
  @include media(max-width 1220px) {
    width: calc(980px/2);
    .box-content{
      width: 100%;
    }
  }
  @include media(max-width 1060px) {
    width: calc(50%);
  }
  @include media(max-width 768px) {
    width: calc(90%);
    margin-left:10%;
  }
}

.video-section-wrap{
  position: relative;
  overflow: hidden;
  .row-background{
    background-color: transparent;
    z-index: 3;
    .box.red{
      background-color: rgba($brand-red, 0.95);
    }
  }
  .video-container{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    .vid-bg{
      min-width: 100%;
      min-height: 100%;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

/****
*** TESTIMONIAL
****/

.full-width-row{
  @include clearfix();
  border-collapse: separate;
  display: flex;
  flex-wrap: wrap;
  .one-third-box{
    padding: 0;
    text-align: center;
    height: calc(100vw/3);
    .logo-container{
      width: 100%;
      height: 100%;
      margin: auto;
      position: relative;
      h2{
        font-size: 0;

      }
      img{
        width: 80%;
        max-width: 200px;
        height: auto;
        // min-height: 12%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        z-index: 5;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        &.shortwide{
          max-width: 295px;
        }
      }
      .testimonial-img{
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        left: 12%;
        right: 12%;
        bottom: 12%;
        top: 12%;
        margin: auto;
        background-color: pink;
      }
      &:hover{
        img{
          opacity: 1;
        }
      }
    }
    .testimonial-container{

    }
    @include media(min-width 1100px){
      width: calc(100%/3);

      &:nth-of-type(2n){
        background-color: $grey-bg-light;
      }
    }
    @include media(max-width 1100px min-width 700px){
      width: 50%;
      height: calc(100vw/2);
      .logo-container img{
        opacity: 1;
      }
      &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(6) ,&:nth-of-type(7), &:nth-of-type(10), &:nth-of-type(11){
        background-color: $grey-bg-light;
      }
    }
    @include media(max-width  700px){
      width: 90%;
      height: calc(100vw/1.1);
      margin: auto;
      .logo-container img{
        opacity: 1;
      }
      &:nth-of-type(2n) {
        background-color: $grey-bg-light;
      }
    }
  }
}
