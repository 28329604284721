@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);
$roboto-font-family: 'Roboto', sans-serif;


/*
*COLORS:
*/
$black-bg: #1d1d1b;
$font-grey: #575756;
$grey-footer: #2E2E2C;
$white: #ffffff;
$brand-red: #e31d23;
$grey-bg-light: #f6f6f6;
