section p{
  line-height: 1.6em;
  font-size: 0.95em;
  margin-bottom: 0.8em;
  &:last-of-type{
    margin-bottom: 0;
  }
}


h1,h2{
font-weight: 300;
font-size: 1.9em;
letter-spacing: 3px;
margin-bottom: 1em;
}
ul{
list-style: none;
}
section{
  p.sub-bold{
    font-size: 0.85em;
    margin: 30px 0;
  }
}
.text{
  font-weight: 300;

  .png-icon {
    max-width: 36px;
    height: auto;
    margin-right: 1em;
    margin-top: 5px;
    float: left;
  }

  ul{
    position: relative;
    li{
      font-size: 0.85em;
      line-height: 1.9em;
      margin-bottom: em(20px);
      padding-left: em(30px);
      &:before{
        content: '\2022';
        position: absolute;
        left: 0;
      }
      &.close {
        margin-bottom: 0;
      }
      &.with-png-icon {
        &:before {
          content: '';
        }
        .png-icon {
          position: absolute;
          left: -3em;
          float: none;

        }
      }
      &.with-png-icon-indented {
        padding-left: 4em;
        min-height: 3em;
        position: relative;
        &:before {
          content: '';
        }
        .png-icon {
        float: none;
        position: absolute;
        left: 0;
        top: -0.35em;
        }
      }
    }
  }
@include media(max-width 768px) {
  h2{
  font-size: 1.75em;
  margin-bottom: 0.9em;
  }
  ul{
    li{
      line-height: 1.6em;
      margin-bottom: em(14px);
      }
    }
  }
}

.main-paragraph {
  &:last-of-type{
    @include media(max-width 768px) {
      margin-bottom: 50px;
    }
  }
.text{
padding-top: 90px;
  img{
    width: 100%;
  }
}
  &.title .text{
    padding-top: 0;
    h2{
      height: 90px;
      margin-bottom: 0;
    }
  }
}

.row-two .main-paragraph.box-content:nth-child(2n-1){
  @include media(max-width 768px) {
    margin-bottom: 0;
  }
}
